<template>
  <el-container>
    <el-main>
      <div class="decorationBox">
        <div class="top">
          <el-button type="primary" size="small" @click="toAdd">+ 添加页面</el-button>
        </div>
        <el-form class="el-form-search" label-width="90px">
          <el-form-item label="页面名称：">
            <el-input v-model="searchForm.keyword" size="small"></el-input>
          </el-form-item>
          <el-form-item label="页面类别：">
            <el-select v-model="searchForm.type" size="small" placeholder="请选择">
              <el-option label="全部" :value="0"></el-option>
              <el-option label="自定义页面" :value="1"></el-option>
              <el-option label="首页" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="创建时间：">
            <el-date-picker
              v-model="searchForm.create_time"
              value-format="timestamp"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              size="small"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label=" " label-width="20px">
            <el-button type="primary" size="small" @click="getPageList(1)">搜索</el-button>
            <el-button type="text" size="small" @click="cancelSearch()">清空搜索条件</el-button>
          </el-form-item>
        </el-form>
        <el-tabs v-model="activeName" @tab-click="handClick">
          <el-tab-pane label="已发布" name="1"></el-tab-pane>
          <el-tab-pane label="草稿箱" name="0"></el-tab-pane>
          <el-tab-pane label="模版审核" name="2"></el-tab-pane>
        </el-tabs>
        <!-- 已发布和草稿箱列表 -->
        <el-table v-if="activeName == '0' || activeName == '1'" :data="tableData" :header-cell-style="{ 'background-color': '#F8F9FA' }">
          <template slot="empty">
            <dataNone />
          </template>
          <el-table-column header-align="center" align="center" prop="id" label="" width="100"></el-table-column>
          <el-table-column header-align="center" align="center" prop="name" label="页面名称" width="200"></el-table-column>
          <el-table-column header-align="center" align="center" prop="type" label="页面类型" width="200">
            <template v-slot="{ row }">
              {{ row.type == 1 ? '自定义页面' : '首页' }}
            </template>
          </el-table-column>
          <el-table-column header-align="center" align="center" prop="show_type" label="展示平台" width="400">
            <template v-slot="{ row }">
              {{ row.typeStr }}
            </template>
          </el-table-column>
          <!-- <el-table-column header-align="center" align="center" label="是否显示" width="200">
            <template v-slot="{ row }">
              <el-switch v-model="row.is_show" :active-value="1" @change="changeShow(row)" :inactive-value="0"> </el-switch>
            </template>
          </el-table-column> -->
          <el-table-column header-align="center" align="center" prop="create_time" label="创建时间">
            <template v-slot="{ row }">
              {{ row.create_time | getDate() }}
            </template>
          </el-table-column>
          <el-table-column header-align="center" align="center" label="操作">
            <template v-if="activeName == 0" v-slot="{ row }">
              <!-- 草稿箱 -->
              <el-button type="text" @click="operation(2, row)">发布</el-button>
              <el-button type="text" @click="operation(0, row)">编辑</el-button>
              <el-button type="text" @click="operation(1, row)">删除</el-button>
            </template>
            <template v-else-if="activeName == 1" v-slot="{ row }">
              <el-button type="text" @click="operation(0, row)">编辑</el-button>
              <el-button type="text" @click="operation(2, row)">复制</el-button>
              <el-button type="text" @click="operation(1, row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 模版列表 -->
        <el-table v-if="activeName == '2'" :data="templateList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
          <template slot="empty">
            <dataNone />
          </template>
          <el-table-column prop="name" label="模版名称" align="center"></el-table-column>
          <el-table-column prop="ti_anme" label="模版分类" align="center"></el-table-column>
          <el-table-column label="模版封面" align="center">
            <template v-slot="{ row }">
              <el-image style="width: 50px; height: 50px" :src="row.images" :preview-src-list="[row.images]"></el-image>
            </template>
          </el-table-column>
          <el-table-column label="模版价格" align="center">
            <template v-slot="{ row }">
              <div :style="{ color: row.is_free ? '' : 'green' }">{{ row.is_free ? `￥${row.money}` : '免费' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="审核状态" align="center">
            <template v-slot="{ row }">
              <div :style="{ color: !row.audit_status ? 'orange' : row.audit_status == 1 ? '#409EFF' : 'red' }">{{ !row.audit_status ? '待审核' : row.audit_status == 1 ? '通过' : '驳回' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="提审时间" align="center">
            <template v-slot="{ row }">
              {{ getDateformat(row.update_time) }}
            </template>
          </el-table-column>
          <el-table-column prop="u_account" label="操作" align="center">
            <template v-slot="{ row }">
              <el-button type="text" size="samll" v-if="row.audit_status != 1" @click="applyTem(row)">申请为模板</el-button>
              <el-button type="text" size="samll" @click="lookTemDetail(row)">审核详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <Paging :total="total" :page="page" :pageNum="pageNum" @updatePageNum="updateData"></Paging>
      </div>
      <el-dialog title="审核详情" :visible.sync="auditRecordFlag" width="1200px">
        <el-table :data="auditRecordList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
          <template slot="empty">
            <No />
          </template>
          <el-table-column label="审核时间" align="center">
            <template v-slot="{ row }">
              {{ getDateformat(row.create_time) }}
            </template>
          </el-table-column>
          <el-table-column prop="u_name" label="审核人员" align="center"></el-table-column>
          <el-table-column label="审核结果" align="center">
            <template v-slot="{ row }">
              {{ row.status == 1 ? '通过' : '驳回' }}
            </template>
          </el-table-column>
          <el-table-column prop="content" label="反馈详情" align="center"></el-table-column>
        </el-table>
        <Paging :total="lookTemTotal" :page="lookTemPage" :pageNum="lookTemRows" @updatePageNum="lookTemUpdateData"></Paging>
      </el-dialog>
      <UploadTemplate :tobeTemFlag="tobeTemFlag" :chooseThat="chooseThat" @success="getTemplateList" @closeTem="closeTem"></UploadTemplate>
    </el-main>
  </el-container>
</template>

<script>
import Paging from '../../../components/paging';
import { getDateformat } from '../../../util/getDate';
import dataNone from '@/components/no';
import UploadTemplate from '@/components/uploadTemplate';
import _ from 'lodash';
export default {
  components: {
    Paging,
    dataNone,
    UploadTemplate,
  },
  data() {
    return {
      getDateformat,
      // 1-已发布 0-草稿箱 2-模版审核
      activeName: '1',
      tableData: [],
      total: 11,
      page: 1,
      pageNum: 10,
      dataIsHave: false,
      // 模板列表
      templateList: [],
      // 审核记录弹框
      auditRecordFlag: false,
      // 审核记录列表
      auditRecordList: [],
      lookTemPage: 1,
      lookTemRows: 10,
      lookTemTotal: 0,
      chooseThat: null,
      tobeTemFlag: false,
      searchForm: {
        keyword: '',
        type: 0,
        create_time: [],
      },
    };
  },
  created() {
    this.getPageList();
    if (this.$route.params.type !== undefined) {
      if (this.$route.params.type === 0) {
        this.activeName = '0';
      } else {
        this.activeName = '1';
      }
      this.getPageList();
    }
  },
  methods: {
    cancelSearch() {
      this.searchForm = {
        keyword: '',
        type: 0,
        create_time: [],
      };
      if (this.activeName !== '2') {
        this.getPageList();
      } else {
        this.getTemplateList();
      }
    },
    handClick() {
      this.page = 1;
      this.cancelSearch();
    },
    // 操作列表项
    operation(type, row) {
      let that = this;
      if (type == 0) {
        this.$axios
          .post(this.activeName == '0' ? this.$api.shopDiy.DraftsInfo : this.$api.shopDiy.ShopDiypage, {
            id: row.id,
          })
          .then(res => {
            if (res.code == 0) {
              res.result.page_values = JSON.parse(res.result.page_values);
              // 发布列表
              if (this.activeName == 1) {
                // f_id 已发布的id
                sessionStorage.setItem('f_id', row.id);
                sessionStorage.setItem(
                  'val',
                  JSON.stringify({
                    data: res.result.page_values,
                  })
                );
              } else {
                // c_id 草稿箱的id
                sessionStorage.setItem('c_id', row.id);
                sessionStorage.setItem(
                  'val',
                  JSON.stringify({
                    data: res.result.page_values,
                  })
                );
              }
              sessionStorage.setItem('#_update', 1);
              that.$router.push({
                path: `/addPages/decorationComponents`,
              });
              return;
            }
            that.$message({
              message: `${res.msg}`,
              type: 'error',
            });
          });
      } else if (type == 1) {
        this.$confirm('确认删除此页面？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(res => {
            this.$axios
              .post(that.activeName == '1' ? this.$api.shopDiy.listdel : this.$api.shopDiy.deleteDrafts, {
                id: row.id,
              })
              .then(res => {
                if (res.code == 0) {
                  that.$message({
                    message: '删除成功',
                    type: 'success',
                  });
                  that.getPageList();
                }
              });
          })
          .catch(err => {});
      } else {
        if (this.activeName == '0') {
          this.$axios
            .post(this.$api.shopDiy.DraftsInfo, {
              id: row.id,
            })
            .then(res => {
              let data = res.result;
              return that.$axios.post(that.$api.shopDiy.createOrUpdatePage, {
                drafts_id: row.id,
                page_name: data.name,
                page_type: data.type,
                show_type: data.show_type,
                page_value: data.page_values,
              });
            })
            .then(res1 => {
              if (res1.code == 0) {
                that.$message.success('发布成功');
                this.activeName = '1';
                this.getPageList();
              } else if (res1.code == -1) {
                that.$message.error('请重新选择客户端类型');
              }
            });
        } else {
          if (row.type == 2) {
            this.$message({
              message: '首页不可复制',
              type: 'warning',
            });
            return;
          }
          this.$axios
            .post(this.$api.shopDiy.copyDiypage, {
              id: row.id,
            })
            .then(res => {
              if (res.code == 0) {
                that.$message({
                  message: '复制成功',
                  type: 'success',
                });
                that.getPageList();
              }
            });
        }
      }
    },
    // 修改分页数据
    updateData(val, status) {
      if (status == 0) {
        this.pageNum = val;
      } else {
        this.page = val;
      }
      // 重新获取数据
      if (this.activeName !== '2') {
        this.getPageList();
      } else {
        this.getTemplateList();
      }
    },
    lookTemUpdateData(val, status) {
      if (status == 0) {
        this.lookTemRows = val;
      } else {
        this.lookTemPage = val;
      }
      this.getAuditLogList(this.chooseThat);
    },
    // 跳转到新增页面
    toAdd() {
      let routeData = this.$router.resolve('/addPages');
      window.open(routeData.href, '_blank');
    },
    // 获取页面列表
    getPageList(style) {
      if (style) {
        this.page = 1;
      }
      let that = this;
      let obj = {
        page: that.page,
        rows: that.pageNum,
      };
      if (this.searchForm.keyword) {
        obj.keyword = this.searchForm.keyword;
      }
      if (this.searchForm.type) {
        obj.type = this.searchForm.type;
      }
      if (this.searchForm.create_time && this.searchForm.create_time.length) {
        obj.create_time = [Math.floor(this.searchForm.create_time[0] / 1000), Math.floor(this.searchForm.create_time[1] / 1000)];
        if (obj.create_time[0] === obj.create_time[1]) {
          obj.create_time[1] += 60 * 60 * 24 - 1;
        }
      }
      this.$axios.post(this.activeName == '0' ? this.$api.shopDiy.DraftsList : this.$api.shopDiy.getSystemList, obj).then(res => {
        if (res.code == 0) {
          that.tableData = res.result.list;
          that.total = res.result.total_number;
          that.tableData.map(item => {
            let typeStr = '';
            // if (item.show_type.includes('1')) {
            //   typeStr += '平台 '
            // }
            if (item.show_type.includes('2')) {
              typeStr += '微信小程序 ';
            }
            if (item.show_type.includes('3')) {
              typeStr += '支付宝小程序 ';
            }
            if (item.show_type.includes('4')) {
              typeStr += '快应用 ';
            }
            if (item.show_type.includes('5')) {
              typeStr += '百度小程序 ';
            }
            if (item.show_type.includes('6')) {
              typeStr += '抖音小程序 ';
            }
            if (item.show_type.includes('7')) {
              typeStr += 'QQ小程序';
            }
            that.$set(item, 'typeStr', typeStr);
          });
        }
      });
    },
    // 更改该项是否显示
    changeShow(item) {
      let that = this;
      this.$axios
        .post(this.$api.shopDiy.isShow, {
          id: item.id,
          is_show: item.is_show,
        })
        .then(res => {
          console.log(res);
        });
    },
    // 获取模版列表
    getTemplateList() {
      this.$axios
        .post(this.$api.shopDiy.myList, {
          page: this.page,
          rows: this.pageNum,
        })
        .then(res => {
          if (res.code === 0) {
            this.templateList = res.result.list;
            this.total = res.result.total_number;
          }
        });
    },
    // 打开模版审核弹框
    lookTemDetail(val) {
      this.chooseThat = val;
      this.getAuditLogList(val);
      this.auditRecordFlag = true;
    },
    // 获取审核记录
    getAuditLogList(val) {
      this.$axios
        .post(this.$api.shopDiy.auditLogList, {
          page: this.lookTemPage,
          rows: this.lookTemRows,
          id: val.id,
        })
        .then(res => {
          if (res.code === 0) {
            this.auditRecordList = res.result.list;
            this.lookTemTotal = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    applyTem(val) {
      this.tobeTemFlag = true;
    },
    closeTem() {
      this.tobeTemFlag = false;
    },
  },
  filters: {
    getDate(index) {
      return getDateformat(index);
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  width: 100%;
  overflow: auto;
  background-color: #fff;
  .decorationBox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .top {
      margin-bottom: 10px;
    }
    .el-table {
      height: 100%;
      /deep/ .el-table__body-wrapper {
        height: calc(100% - 48px);
        overflow-y: auto;
      }
    }
  }
}
</style>
